/**
 * Stores mapping between a page's key and it's name, icon, component and path
 * @module pagesConfig
 * @summary Page configurations
 */

import * as pages from './asyncPages.js'
import categories from './drawerCategories.js'
import DashboardIcon from '@material-ui/icons/Dashboard'
import MapIcon from '@material-ui/icons/Map'
import ReportIcon from '@material-ui/icons/MultilineChart'
import EventsIcon from '@material-ui/icons/ScatterPlot'
import VehicleIcon from '@material-ui/icons/AirportShuttle'
import ImmobilizeIcon from '@material-ui/icons/Block'
import DriverIcon from '@material-ui/icons/AirlineSeatReclineNormal'
import UsersIcon from '@material-ui/icons/People'
import AccountIcon from '@material-ui/icons/AccountBox'
import RoutesIcon from '@material-ui/icons/Directions'
import TripsIcon from '@material-ui/icons/SwapCalls'
import ActivityIcon from '@material-ui/icons/NearMe'
import FuelIcon from '@material-ui/icons/LocalGasStation'
import OBDDashboardIcon from '@material-ui/icons/SettingsInputSvideo'
import AOIIcon from '@material-ui/icons/SelectAll'
import Videocam from '@material-ui/icons/Videocam'
import SummaryIcon from '@material-ui/icons/Assessment'
import SchoolIcon from '@material-ui/icons/AccountBalance'
import CallToActionIcon from '@material-ui/icons/CallToAction'
import SettingsAppIcon from '@material-ui/icons/SettingsApplications'

export default {
  ANALYTICS_DASHBOARD: {
    name: 'Analytics',
    icon: MapIcon,
    path: '/home/analytics',
    category: categories.HOME,
    order: 2,
    component: pages.AsyncAnalyticsDashboard
  },

  SCHOOL_DASHBOARD: {
    name: 'Analytics',
    icon: MapIcon,
    path: '/home/analytics',
    category: categories.HOME,
    order: 2,
    component: pages.AsyncSchoolDashboard
  },

  GOOGLE_MAPS: {
    name: 'Dashboard',
    icon: DashboardIcon,
    path: '/home/dashboard',
    category: categories.HOME,
    order: 1,
    component: pages.AsyncGoogleMapsDashboard
  },

  OPENSTREET_MAPS: {
    name: 'Dashboard',
    icon: DashboardIcon,
    path: '/home/dashboard',
    category: categories.HOME,
    order: 1,
    component: pages.AsyncOSMapsDashboard
  },

  REPORT: {
    name: 'Legacy Reports',
    icon: ReportIcon,
    path: '/home/report',
    category: categories.REPORT,
    order: 3,
    component: pages.AsyncReport
  },

  HEAT_MAP: {
    name: 'Heat Map Reports',
    icon: ReportIcon,
    path: '/home/heat-map',
    category: categories.REPORT,
    order: 3,
    component: pages.AsyncHeatMap
  },

  SUPPORT_DASHBOARD: {
    name: 'Support Dashboard',
    icon: CallToActionIcon,
    path: '/home/support-dashboard',
    order: 3,
    component: pages.AsyncGoogleMapsDashboard
  },

  IMMOBILIZE: {
    name: 'Mobilize/Immobilize',
    icon: ImmobilizeIcon,
    path: '/home/mobilize-immobilize',
    category: categories.ACTIONS,
    order: 1,
    component: pages.AsyncImmobilize
  },

  ALERTS_CONFIG: {
    name: 'Alerts',
    icon: EventsIcon,
    path: '/home/alerts',
    category: categories.ACTIONS,
    order: 2,
    component: pages.AsyncAlertsDashboard
  },

  PROVISIONING: {
    name: 'Provisioning',
    icon: OBDDashboardIcon,
    path: '/home/provisioning',
    category: categories.ACTIONS,
    order: 3,
    component: pages.Provisioning
  },

  EOL_DASHBOARD: {
    name: 'EOL Dashboard',
    icon: DashboardIcon,
    path: '/home/eol-dashboard',
    category: categories.HOME,
    order: 1,
    component: pages.EOLDashboard
  },

  EOL_REPORT: {
    name: 'EOL Report',
    icon: OBDDashboardIcon,
    path: '/home/eol-report',
    category: categories.HOME,
    order: 1,
    component: pages.EOLReport
  },

  SCHOOL_INSIGHTS: {
    name: 'Insights',
    icon: OBDDashboardIcon,
    path: '/home/insights',
    category: categories.ACTIONS,
    order: 3,
    component: pages.Insights
  },

  AOI: {
    name: 'AOI',
    icon: AOIIcon,
    path: '/home/AOI',
    category: categories.GEO,
    order: 1,
    component: pages.AsyncAOI
  },

  FOTA_MANAGEMENT: {
    name: 'FotaManagement',
    icon: AOIIcon,
    path: '/home/fota-management',
    category: categories.ACTIONS,
    order: 4,
    component: pages.FotaManagement
  },

  SCHOOL_AOI: {
    name: 'Stops',
    icon: AOIIcon,
    path: '/home/AOI',
    category: categories.GEO,
    order: 1,
    component: pages.AsyncSchoolAOI
  },

  ROUTES: {
    name: 'Routes',
    icon: RoutesIcon,
    path: '/home/routes',
    category: categories.GEO,
    order: 2,
    component: pages.AsyncRoutes
  },

  SIM_MANAGEMENT: {
    name: 'Sim Management',
    icon: RoutesIcon,
    path: '/home/sims',
    category: categories.GEO,
    order: 2,
    component: pages.InventorySim
  },

  SCHOOL_ROUTES: {
    name: 'Auto-Routes',
    icon: RoutesIcon,
    path: '/home/auto-routes',
    category: categories.INTEGRATIONS,
    order: 2,
    component: pages.AsyncSchoolRoutes
  },

  TRIPS: {
    name: 'Trips',
    icon: TripsIcon,
    path: '/home/trips',
    category: categories.GEO,
    order: 3,
    component: pages.AsyncTrips
  },

  SCHOOL_TRIPS: {
    name: 'Auto-Trips',
    icon: TripsIcon,
    path: '/home/auto-trips',
    category: categories.INTEGRATIONS,
    order: 3,
    component: pages.AsyncSchoolTrip
  },

  ACTIVITY: {
    // name: 'Activity',
    name: 'Trips',

    icon: ActivityIcon,
    path: '/home/activity',
    category: categories.GEO,
    order: 4,
    component: pages.AsyncActivity
  },

  BCT: {
    name: 'BCT Management',
    icon: ActivityIcon,
    path: '/home/bct-management',
    category: categories.GEO,
    order: 4,
    component: pages.BctManagement
  },

  FUEL_DASHBOARD: {
    name: 'Fuel Dashboard',
    icon: FuelIcon,
    path: '/home/fuel-dashboard',
    category: categories.INTEGRATIONS,
    order: 1,
    component: pages.AsyncFuelDashboard
  },

  LIVE_DATA: {
    name: 'Live Data Report',
    icon: FuelIcon,
    path: '/home/live-data',
    category: categories.INTEGRATIONS,
    order: 1,
    component: pages.LiveData
  },

  DATA_SYNC: {
    name: 'Data Sync Report',
    icon: FuelIcon,
    path: '/home/sync-data',
    category: categories.INTEGRATIONS,
    order: 1,
    component: pages.SyncData
  },

  CONTENT_MANAGEMENT: {
    name: 'Content Management',
    icon: FuelIcon,
    path: '/home/content-management',
    category: categories.INTEGRATIONS,
    order: 1,
    component: pages.ContentManagement
  },

  MECHANIC_PROVISIONING_REPORTS: {
    name: 'Mechanic Provision Report',
    icon: FuelIcon,
    path: '/home/mechanic-provisioning-reports',
    category: categories.INTEGRATIONS,
    order: 1,
    component: pages.MechanicProvisionReports
  },

  MECHANIC_NOTIFICATIONS: {
    name: 'Mechanic Notifications',
    icon: FuelIcon,
    path: '/home/mechanic-notifications',
    category: categories.INTEGRATIONS,
    order: 1,
    component: pages.MechanicNotifications
  },

  MECHANIC_NOTIFICATIONS_REPORT: {
    name: 'Mechanic Notifications Report',
    icon: FuelIcon,
    path: '/home/mechanic-notifications-report',
    category: categories.INTEGRATIONS,
    order: 1,
    component: pages.MechanicNotificationsReport
  },

  VEHICLE_HEALTH_REPORT: {
    name: 'Vehicle Health Reports',
    icon: FuelIcon,
    path: '/home/vehicle-health-report',
    category: categories.INTEGRATIONS,
    order: 1,
    component: pages.VehicleHealthReport
  },

  LOGS_FROM_ES: {
    name: 'Logs From ES',
    icon: FuelIcon,
    path: '/home/logs-from-es',
    category: categories.INTEGRATIONS,
    order: 5,
    component: pages.AsyncLogsFromES
  },

  OBD_DASHBOARD: {
    name: 'OBD Dashboard',
    icon: OBDDashboardIcon,
    path: '/home/obd-dashboard',
    category: categories.INTEGRATIONS,
    order: 2,
    component: pages.AsyncOBD
  },

  SCHOOL: {
    name: 'Schools',
    icon: SchoolIcon,
    path: '/home/manage-school',
    category: categories.MANAGEMENT,
    order: 1,
    component: pages.AsyncSchool
  },

  SCHOOL_STUDENTS: {
    name: 'Students',
    icon: UsersIcon,
    path: '/home/manage-students',
    category: categories.MANAGEMENT,
    order: 2,
    component: pages.AsyncSchoolStudents
  },

  MANAGE_VEHICLES: {
    name: 'RE VEHICLES',
    icon: UsersIcon,
    path: '/home/manage-vehicles',
    category: categories.MANAGEMENT,
    order: 5,
    component: pages.AsyncVehicles
  },

  MANAGE_MECHANIC: {
    name: 'Mechanic Dashboard',
    icon: DashboardIcon,
    path: '/home/manage-mechanic',
    category: categories.MANAGEMENT,
    order: 5,
    component: pages.ManageMechanic
  },

  // MANAGE_VEHICLES: {
  //   name: 'Manage Mechanic',
  //   icon: UsersIcon,
  //   path: '/home/manage-mechanic',
  //   category: categories.MANAGEMENT,
  //   order: 5,
  //   component: pages.ManageMechanic
  // },

  // MANAGE_USERS: {
  //   name: 'RE VEHICLES',
  //   icon: UsersIcon,
  //   path: '/home/manage-users',
  //   category: categories.MANAGEMENT,
  //   order: 5,
  //   component: pages.ReVehicles
  // },

  MANAGE_DRIVERS: {
    name: 'Drivers',
    icon: DriverIcon,
    path: '/home/manage-devices',
    category: categories.MANAGEMENT,
    order: 4,
    component: pages.AsyncDrivers
  },

  DEVICE_MANAGEMENT: {
    name: 'TCU Devices',
    icon: DriverIcon,
    path: '/home/manage-devices',
    category: categories.MANAGEMENT,
    order: 4,
    component: pages.Devices
  },

  MANAGE_USERS: {
    name: 'Users',
    icon: UsersIcon,
    path: '/home/manage-users',
    category: categories.MANAGEMENT,
    order: 5,
    component: pages.AsyncUsers
  },
  // MANAGE_USERS: {
  //   name: 'TCU Devices',
  //   icon: UsersIcon,
  //   path: '/home/manage-users',
  //   category: categories.MANAGEMENT,
  //   order: 5,
  //   component: pages.TcuDevice
  // },
  MANAGE_ACCOUNT: {
    name: 'Account',
    icon: AccountIcon,
    path: '/home/account',
    category: categories.MANAGEMENT,
    order: 6,
    component: pages.AsyncAccount
  },

  DONGLE_DEVICES: {
    name: 'Dongle Devices',
    icon: AccountIcon,
    path: '/home/dongleDevices',
    category: categories.MANAGEMENT,
    order: 4,
    component: pages.DongalDevice
  },

  VEHICLES_MANAGEMENT_CONFIG: {
    name: 'Vehicles Management Config',
    icon: SettingsAppIcon,
    path: '/home/vehicles-management-config',
    category: categories.MANAGEMENT,
    order: 7,
    component: pages.AsyncVehiclesManagementConfig
  },

  DTC_CONFIGURATION: {
    name: 'DTC Configuration',
    icon: SettingsAppIcon,
    path: '/home/dtc-configuration',
    category: categories.MANAGEMENT,
    order: 8,
    component: pages.AsyncDTCConfiguration
  },

  KDASH_PROJECTION_CONFIGURATION: {
    name: 'Tripper Dash Projection Configuration',
    icon: SettingsAppIcon,
    path: '/home/kdash-projection-configuration',
    category: categories.MANAGEMENT,
    order: 9,
    component: pages.AsyncVKDashProjectionConfiguration
  },

  SUMMARY_ELECTRIC: {
    name: 'Summary',
    icon: SummaryIcon,
    path: '/home/summary-electric',
    category: categories.INTEGRATIONS,
    order: 1,
    component: pages.AsyncElectricSummary
  },

  LIVE_VIDEO: {
    name: 'Live Video',
    icon: Videocam,
    path: '/home/live-video',
    category: categories.INTEGRATIONS,
    order: 4,
    component: pages.AsyncVideoStream
  },

  REIL_VIDEO: {
    name: 'Live Video',
    icon: Videocam,
    path: '/home/live-video/multi-cam',
    category: categories.INTEGRATIONS,
    order: 4,
    component: pages.AsyncReilVideo
  },

  CONTINENTAL_DASHBOARD: {
    name: 'TPMS',
    icon: MapIcon,
    path: '/home/tpms',
    category: categories.INTEGRATIONS,
    order: 4,
    component: pages.AsyncContinental
    ////////////////////////////// component: pages.AsyncRawDataFiles
  }
}
